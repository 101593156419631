import { MagaluService } from '@magalu/webzord';

const requestWebzord = ({ serviceName, callbackId, serviceArgs, callback, showLoading }) =>
  MagaluService.request({
    serviceArgs,
    serviceName,
    callbackId,
    callback,
    showLoading
  });

export default requestWebzord;
