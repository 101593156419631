import { NATIONAL_CURRENCY } from 'constants/currency';

const isInternationalPrice = (price) => {
  if (!price?.currency) {
    return false;
  }

  return price.currency !== NATIONAL_CURRENCY;
};

export default isInternationalPrice;
