import {
  basket as routeBasket,
  login as routeLogin,
  delivery as routeDelivery,
  payment as routePayment,
  review as routeReview,
} from 'constants/route-names';

export const statusColors = {
  neutral: '#8c8c8c',
  current: '#0086ff',
  passed: '#58c22e',
};

export const INITIAL_STATUS = {
  [routeBasket]: statusColors.neutral,
  [routeLogin]: statusColors.neutral,
  [routeDelivery]: statusColors.neutral,
  [routePayment]: statusColors.neutral,
  [routeReview]: statusColors.neutral,
};

export const BASKET_STATUS = {
  [routeBasket]: statusColors.current,
  [routeLogin]: statusColors.neutral,
  [routeDelivery]: statusColors.neutral,
  [routePayment]: statusColors.neutral,
  [routeReview]: statusColors.neutral,
};

export const LOGIN_STATUS = {
  [routeBasket]: statusColors.passed,
  [routeLogin]: statusColors.current,
  [routeDelivery]: statusColors.neutral,
  [routePayment]: statusColors.neutral,
  [routeReview]: statusColors.neutral,
};

export const DELIVERY_STATUS = {
  [routeBasket]: statusColors.passed,
  [routeLogin]: statusColors.passed,
  [routeDelivery]: statusColors.current,
  [routePayment]: statusColors.neutral,
  [routeReview]: statusColors.neutral,
};

export const PAYMENT_STATUS = {
  [routeBasket]: statusColors.passed,
  [routeLogin]: statusColors.passed,
  [routeDelivery]: statusColors.passed,
  [routePayment]: statusColors.current,
  [routeReview]: statusColors.neutral,
};
