import { OTP_KEYS } from 'constants/otp-validator';
import { getLocalStorageItem, setLocalStorageItem } from './storage';

export const setOtpStorage = (deliveryMode, data) => {
  const deliveryModeKeys = OTP_KEYS[deliveryMode];

  setLocalStorageItem(deliveryModeKeys.expiresAt, data.expires_at);
  setLocalStorageItem(deliveryModeKeys.id, data.id);
};

export const getOtpStorage = (deliveryMode) => {
  const deliveryModeKeys = OTP_KEYS[deliveryMode];

  return {
    currentExpiresAt: getLocalStorageItem(deliveryModeKeys.expiresAt),
    currentOtpId: getLocalStorageItem(deliveryModeKeys.id),
  };
};
