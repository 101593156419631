import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize, change } from 'redux-form';
import { isEmpty } from 'lodash';

import * as customerMessages from 'constants/customer-messages';
import * as paymentTypes from 'constants/payment-types';

import { DEFAULT_CARDS, detectCardFlag } from 'utils/card';
import * as validators from 'utils/validators';
import { onlyNumbers } from 'utils/value-cleaners';
import { encrypt } from 'utils/encrypt';
import { addLeadingZero } from 'utils/maskers';

import {
  encryptedNewCardData,
  newCardData,
} from 'api/models/payment-models';

import * as orderActions from 'reducers/order';

import NewCardForm from 'components/Payment/Forms/NewCardForm/NewCardForm';

const errorsNewCardForm = ({
  expirationMonth,
  expirationYear,
  number,
  installments,
  CVC,
  flag,
  fullName,
  enabledCards,
}, props) => {
  const errors = {};

  if (!validators.expirationDate(expirationMonth, expirationYear)) {
    errors.expirationYear = customerMessages.invalidExpirationDate;
  }

  if (!validators.isValidCardNumber(onlyNumbers(number))) {
    if (!flag) {
      errors.number = customerMessages.invalidCardNumber;
    }
    if (!validators.validateCardNumber(onlyNumbers(number))) {
      errors.number = customerMessages.invalidLuhnCardNumber;
    }
  }

  if (!errors.number && validators.isValidVirtualDebitEloCardNumber(onlyNumbers(number))) {
    errors.number = customerMessages.invalidCreditValidVirtualDebitNumber;
  }

  if (props.allowedBins && !validators.isCardNumberBinAllowed(onlyNumbers(number), props.allowedBins)) {
    errors.number = customerMessages.cardNumberNotAllowed;
  }

  if (installments < 0) {
    if (!flag && !validators.validateCardNumber(onlyNumbers(number))) {
      errors.installments = customerMessages.invalidCardNumberForInstallments;
    } else if (!flag && validators.validateCardNumber(onlyNumbers(number))){
      errors.installments = customerMessages.invalidInstallmentsFlag;
    } else {
      errors.installments = customerMessages.invalidInstallmentsNumber;
    }
  }

  if (validators.isValidCardNumber(onlyNumbers(number)) && !flag) {
    errors.number = customerMessages.flagNotSelected;
  }

  if (!validators.cvc(CVC, flag, enabledCards)) {
    errors.CVC = customerMessages.invalidCVC;
  }

  if (!validators.fullName(fullName)) {
    errors.fullName = customerMessages.invalidFullNameOnCard;
  }

  return errors;
};

@reduxForm({
  form: 'newCard',
  fields: [
    'number',
    'flag',
    'fullName',
    'expirationMonth',
    'expirationYear',
    'CVC',
    'installments',
    'saveCard',
    'setAsDefault',
    'campaignCode',
    'itemsSku',
  ],
  validate: errorsNewCardForm
})
@connect(({
  form,
  order,
  installments,
  basket
}) => ({
  form,
  ...order,
  ...installments,
  ...basket,
}), {
  ...orderActions,
  initializeForm: initialize,
  changeField: change
})
export default class NewCardFormContainer extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    clickedFlag: PropTypes.string,
    installmentsPerType: PropTypes.object.isRequired,
    changeClickedFlag: PropTypes.func.isRequired,
    postPlaceOrder: PropTypes.func.isRequired,
    initializeForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handlePlaceOrderError: PropTypes.func.isRequired,
    shippingPackages: PropTypes.object.isRequired,
    redirectToAddress: PropTypes.func.isRequired,
    redirectToBasket: PropTypes.func.isRequired,
    changeField: PropTypes.func.isRequired,
    campaignCode: PropTypes.string,
    itemsSku: PropTypes.array,
    allowedBins: PropTypes.array,
    enableEncryptedCard: PropTypes.bool,
    items: PropTypes.array,
    tmxSessionID: PropTypes.string
  };

  componentWillMount() {
    this.props.initializeForm(
      'newCard',
      {
        saveCard: true,
        installments: '-1',
        flag: '',
        campaignCode: this.props.campaignCode,
        itemsSku: this.props.itemsSku
      },
      Object.keys(this.props.fields),
    );
  }

  componentWillReceiveProps(nextProps) {
    const {
      fields,
      changeField,
      clickedFlag = ''
    } = nextProps;
    const possibleCards = this.getEnabledCards();
    const flag = detectCardFlag(fields.number.value, possibleCards) || clickedFlag;
    if (fields.flag.value !== flag) {
      changeField('newCard', 'flag', flag);
    }
  }

  getDisabledFlags = () => {
    const { shippingPackages: { hasMarketplaceItems } } = this.props;
    return hasMarketplaceItems && [
      'aura'
    ] || [];
  };

  // This uses getDisabledFlags
  getEnabledCards = () => {
    const disabledFlags = this.getDisabledFlags();
    const enabledCards = {};

    Object.keys(DEFAULT_CARDS)
      .forEach((flag) => {
        if (disabledFlags.indexOf(flag) === -1) {
          enabledCards[flag] = DEFAULT_CARDS[flag];
        }
      });

    return enabledCards;
  };

  getAllowedBins = () => {
    const { allowedBins } = this.props;
    return allowedBins;
  };

  render() {
    const {
      clickedFlag = '',
      installmentsPerType,
      changeClickedFlag,
      postPlaceOrder,
      handleSubmit,
      shippingPackages: { hasMarketplaceItems },
      redirectToAddress,
      redirectToBasket,
      handlePlaceOrderError,
      enableEncryptedCard,
      items,
      tmxSessionID,
    } = this.props;
    const enabledCards = this.getEnabledCards();
    const bins = this.getAllowedBins();

    return (
      <NewCardForm
        {...this.props.fields}
        disabledFlags={this.getDisabledFlags()}
        clickedFlag={clickedFlag}
        installmentsList={installmentsPerType[this.props.fields.flag.value || clickedFlag] || []}
        handleFlagClick={function handleClickedFlag(e) {
          changeClickedFlag(e.currentTarget.dataset.flag);
        }}
        handleSubmit={handleSubmit(values => new Promise((resolve, reject) => {
          const {
            number,
            fullName,
            CVC,
            installments,
            flag,
            expirationMonth,
            expirationYear,
            saveCard,
            setAsDefault
          } = values;
          const errors = errorsNewCardForm(Object.assign({}, values, { enabledCards }), { allowedBins: bins });

          if (!isEmpty(errors)) {
            return reject(errors);
          }

          const installmentsQuantity = installmentsPerType[flag][installments].numberOfInstallments;

          const cardData = !!enableEncryptedCard
            ? encryptedNewCardData({
              installmentsQuantity,
              flag,
              saveCard,
              setAsDefault,
              hasMarketplaceItems,
              encryptedCreditCard: encrypt(
                {
                  'card_holder_name': fullName,
                  'card_number': number.replace(/ /g, ''),
                  'card_exp_date': `${addLeadingZero(expirationMonth)}/${expirationYear}`,
                  'card_security_code': CVC
                }
              ),
            })
            : newCardData({
              fullName,
              number,
              expirationMonth,
              expirationYear,
              CVC,
              installmentsQuantity,
              flag,
              saveCard,
              setAsDefault,
              hasMarketplaceItems,
            });

          return postPlaceOrder(
            {...cardData, items, customer_risk_analysis_id: tmxSessionID},
            paymentTypes.newCard,
            redirectToAddress,
            redirectToBasket,
            number.charAt(0)
          )
            .catch((err) => {
              handlePlaceOrderError(err);
            });
        }))}
      />
    );
  }
}
