import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as paymentTypes from 'constants/payment-types';
import * as routeNames from 'constants/route-names';
import { sameDayAvailability } from 'constants/customer-messages';

import { toBRL } from 'utils/currency';
import { isClube } from 'utils/channels';
import { storesHaveSameDayPickup } from 'utils/packages';

import { getChannelConfig } from 'utils/session';

import Responsive from 'components/Responsive';
import CreditcardPayment from 'components/ConfirmOrder/CreditCardPayment';
import ShowDeliveryTime from 'components/ConfirmOrder/ShowDeliveryTime/ShowDeliveryTime';
import StorePickupToken from 'components/shared/StorePickupToken/StorePickupToken';
import {NATIONAL_CURRENCY} from 'constants/currency';

import './ConfirmOrderBox.scss';

const { bool, number, string, object, node, array } = PropTypes;

export class ConfirmOrderBoxComponent extends Component {
  static propTypes = {
    id: string.isRequired,
    itemQuantity: number,
    totalAmount: string.isRequired,
    payments: array.isRequired,
    barCode: string,
    bankSlip: object,
    children: node,
    showFollowOrderButtons: bool,
    showPrintOrderButton: bool,
    shippingTypes: object,
    packagesCount: number,
    customerEmail: string.isRequired,
    isMobile: bool,
    confirmOrderTrackUrl: string.isRequired,
    salesChannel: string,
  };

  render() {
    const {
      id,
      itemQuantity,
      totalAmount,
      payments,
      children,
      bankSlip = {},
      showFollowOrderButtons = true,
      showPrintOrderButton = true,
      shippingTypes: { deliveries, stores } = {},
      packagesCount,
      customerEmail,
      isMobile,
      confirmOrderTrackUrl,
      salesChannel,
      currency
    } = this.props;

    let packageIndex = 0;

    const channelConfigStorePickupTokenValue = Number(
      getChannelConfig('store_pickup_token_value')
    );

    const hasInternationalProduct = currency && currency !== NATIONAL_CURRENCY;

    const showStorePickupToken = stores?.some((store) => {
      const currentStore = Object.values(store)[0];
      const validateSubtotal = currentStore.packages.some((storePackage) =>
        storePackage.products.some(
          (valueProduct) => valueProduct.subtotal >= channelConfigStorePickupTokenValue
        )
      );

      return validateSubtotal;
    });

    return (
      <div className="ConfirmOrderBox clearfix">
        <div className="ConfirmOrderBox-leftContainer">
          <p>Número do pedido</p>
          <p className="ConfirmOrderBox-orderNumber">{id}</p>

          {showFollowOrderButtons && isMobile ? (
            <div>
              <div className="ConfirmOrderHeader-description">
                {' '}
                Confirmação de pedido enviada para:{' '}
                <span className="ConfirmOrderHeader-email">{customerEmail}</span>.
              </div>
              <a className="ConfirmOrderBox-show-details" href={confirmOrderTrackUrl}>
                Acompanhe seus Pedidos{' '}
              </a>
            </div>
          ) : (
            <div className="ConfirmOrderBox-buttonsHolder">
              <a
                href={
                  isClube(salesChannel)
                    ? confirmOrderTrackUrl
                    : `${confirmOrderTrackUrl}/${id}`
                }
                className="ConfirmOrderBox-trackOrder"
                data-ga={`{"category": "Pedido Concluído", "action": "Acompanhar Pedido", "label": "${payments[0].name}"}`}>
                Acompanhar pedido
              </a>
              {showPrintOrderButton && (
                <a
                  href={`/${routeNames.receipt}?orderId=${id}`}
                  target="_blank"
                  className="ConfirmOrderBox-print"
                  data-ga={`{"category": "Pedido Concluído", "action": "Imprimir Comprovante", "label": "${payments[0].name}"}`}>
                  Imprimir comprovante
                </a>
              )}
            </div>
          )}
        </div>

        {!isMobile && (
          <div className="ConfirmOrderBox-rightContainer">
            <p>
              Sacola ({itemQuantity} ite{`${itemQuantity > 1 ? 'ns' : 'm'}`})
            </p>
            <p>
              Total (produtos {`${hasInternationalProduct ? '+ impostos ' : ''}`}+ frete):{' '}
              <span className="ConfirmOrderBox-colorBlue">{toBRL(totalAmount)}</span>
            </p>

            {deliveries.packages.map((package_, index) => {
              packageIndex += 1;
              return (
                <ShowDeliveryTime
                  key={`package_${index}`}
                  index={packageIndex}
                  packagesCount={packagesCount}
                  store={package_.store}
                  time={package_.time}
                  scheduledParameters={package_.scheduledParameters}
                  deliveryType={package_.deliveryType}
                  description={package_.description}
                />
              );
            })}

            {stores.map((store, storeIndex) => {
              const currentStore = Object.values(store)[0];

              return (
                <span key={`stores_${storeIndex}`}>
                  {currentStore.packages.map((storePackage, index) => {
                    packageIndex += 1;
                    return (
                      <ShowDeliveryTime
                        key={`store_${index}`}
                        index={packageIndex}
                        packagesCount={packagesCount}
                        store={storePackage.store}
                        time={storePackage.time}
                        scheduledParameters={storePackage.scheduledParameters}
                        deliveryType={storePackage.deliveryType}
                        description={storePackage.description}
                      />
                    );
                  })}
                </span>
              );
            })}

            {storesHaveSameDayPickup(stores) && (
              <p className="ConfirmOrderBox-samedayMessage">{sameDayAvailability}</p>
            )}

            <div className="ConfirmOrderBox-payment">
              <div className="ConfirmOrderBox-payment-header">Pagamento: </div>
              <div className="ConfirmOrderBox-payment_types">
                {payments.map((payment, index) => {
                  const amountPerInstallment =
                    payments.length > 1 ? `(${toBRL(payment.amountPerInstallment)})` : '';
                  return (
                    <div key={`payment-${index}`}>
                      {(payment.type === paymentTypes.bankSlip && (
                        <span>
                          Boleto bancário{' '}
                          <span className="ConfirmOrderBox-payment_types-amount">
                            {amountPerInstallment}
                          </span>
                          <span className="ConfirmOrderBox-payment_types-expiration">
                            (Vencimento: {bankSlip.expiration})
                          </span>
                        </span>
                      )) ||
                        (payment.type === paymentTypes.valeCompra && (
                          <span>
                            Vale Compra{' '}
                            <span className="ConfirmOrderBox-payment_types-amount">
                              {amountPerInstallment}
                            </span>
                          </span>
                        )) ||
                        (payment.type === paymentTypes.virtualDebitElo && (
                          <span>
                            Cartão de débito virtual Caixa{' '}
                            <span className="ConfirmOrderBox-payment_types-amount">
                              {amountPerInstallment}
                            </span>
                          </span>
                        )) ||
                        (payment.type === paymentTypes.pix && (
                          <span>
                            Pix{' '}
                            <span className="ConfirmOrderBox-payment_types-amount">
                              {amountPerInstallment}
                            </span>
                          </span>
                        )) || (
                        <CreditcardPayment
                          installmentText={payment.installmentText}
                          amountPerInstallment={payment.amountPerInstallment}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {children}

        {showStorePickupToken && (
          <span className="ConfirmOrderBox-StorePickupToken">
            <StorePickupToken />
          </span>
        )}
      </div>
    );
  }
}

const ConfirmOrderBox = Responsive(ConfirmOrderBoxComponent);
export { ConfirmOrderBox };
