export const sac = 'https://atendimento.magazineluiza.com.br/';
export const telesalesInfo = 'https://www.magazineluiza.com.br/televendas/info-televendas/';
export const onlineChat = 'https://atendimento.magazineluiza.com.br/hc/pt-br?open=chat_televendas';
export const security = 'https://www.magazineluiza.com.br/estaticas/seguranca-maxima/';
export const ebit = 'https://www.ebit.com.br/552';
export const internetSegura = 'https://www.internetsegura.org/';
export const catalog = 'https://www.magazineluiza.com.br';
export const pictureBaseUrl = 'i.mlcdn.com.br';
export const correiosUrl = 'https://buscacepinter.correios.com.br/app/endereco/index.php';
export const privacyUrl = 'https://especiais.magazineluiza.com.br/politica-de-privacidade/';
export const paymentContractUrl = 'https://especiais.magazineluiza.com.br/termo-compra-venda/';
export const confirmOrderPrintSlipUrl = 'https://www.magazineluiza.com.br/boleto/novo/?numOrder=';
export const confirmOrderPrintReceiptUrl = 'https://www.magazineluiza.com.br/compra/comprovante/imprimir';
export const customerSpace = 'https://www.magazineluiza.com.br/seu-espaco/';
export const notFoundUrl = 'https://www.magazineluiza.com.br/error-404/';
export const giftCardUrl = 'https://conteudo.magazineluiza.com.br/site/presentes-embalagem-regra.html';
export const nationalResetPasswordIdentificationUrl = `${process.env.REACT_APP_NATIONAL_BASE_URL || ''}#/recuperar-senha`;
export const nationalResetPasswordByOtpIdentificationUrl = `${process.env.REACT_APP_NATIONAL_BASE_URL || ''}#/confirmar-identidade`;
export const sacNumber = '08003100002';
export const magalupayAbboutUrl = 'https://especiais.magazineluiza.com.br/magalupay/';
export const magalupayTermsUrl = 'https://especiais.magazineluiza.com.br/termos-de-uso-para-magalupay/';
export const googleAccountsUrl = 'https://accounts.google.com/gsi/client';
