import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SacolaIcon from 'icons/navigation/sacola.svg';
import IdentificacaoIcon from 'icons/navigation/identificacao.svg';
import EntregaIcon from 'icons/navigation/entrega.svg';
import PagamentoIcon from 'icons/navigation/pagamento.svg';
import BasketIcon from 'icons/navigation/basket.svg';
import DeliveryIcon from 'icons/navigation/delivery.svg';
import PaymentIcon from 'icons/navigation/payment.svg';
import ReviewIcon from 'icons/navigation/review.svg';
import {
  basket as routeBasket,
  login as routeLogin,
  delivery as routeDelivery,
  payment as routePayment,
  review as routeReview,
} from 'constants/route-names';
import isWalletEnable from 'utils/isWalletEnable';
import './styles.scss';

export default class Icon extends Component {
  static propTypes = {
    routeName: PropTypes.string.isRequired,
    status: PropTypes.object.isRequired,
  };

  render() {
    const { routeName, status } = this.props;
    if(isWalletEnable()) {
      switch(routeName) {
        case routeBasket:
          return <BasketIcon className="NavigationIcon" fill={status[routeBasket]} />;
        case routeDelivery:
          return <DeliveryIcon className="NavigationIcon" fill={status[routeDelivery]}/>;
        case routePayment:
          return <PaymentIcon className="NavigationIcon" fill={status[routePayment]}/>;
        case routeReview:
          return <ReviewIcon className="NavigationIcon" fill={status[routeReview]}/>;
        default:
          return null;
      }
    } else {
      switch(routeName) {
        case routeBasket:
          return <SacolaIcon className="NavigationIcon" fill={status[routeBasket]}/>; 
        case routeLogin:
          return <IdentificacaoIcon className="NavigationIcon" fill={status[routeLogin]}/>;
        case routeDelivery:
          return <EntregaIcon className="NavigationIcon" fill={status[routeDelivery]}/>;
        case routePayment:
          return <PagamentoIcon className="NavigationIcon" fill={status[routePayment]}/>;
        default:
          return null;
      }
    }
   
  }
}
