import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  setFirstOtpId,
  getFirstOtpId,
  getSecondOtpId,
  resetOtpId,
  setSecondOtpId,
} from 'utils/manageOtpIds';

import miniappValidateSession from 'utils/miniappValidateSession';
import { getChannelConfig } from 'utils/session';

import * as customerActions from 'reducers/customer';
import * as customerAreaActions from 'reducers/customer-area';
import * as loadingActions from 'reducers/loading';
import * as otpActions from 'reducers/send-otp';
import * as validateActions from 'reducers/validate-otp';
import * as routeNames from 'constants/route-names';
import { channelCustomerArea } from 'constants/route-names';

import ValidateAccountOtp from 'components/ValidateAccountOtp/ValidateAccountOtp';

import './CustomerAreaConfirmPage.scss';
import { SCREEN_NAME_CODE, SCREEN_NAME_OTP } from 'utils/data-layer/customerArea';
import { getOtpStorage, setOtpStorage } from 'utils/otp';

const mapStateToProps = ({ customer, failOtp, sendOtp, validateOtp, customerArea }) => ({
  customerInfo: customer.customerInfo,
  customerRequesting: customer.isRequesting,
  sendOtp,
  failOtp,
  isRequestingValidateOtp: validateOtp.isRequesting,
  isRequestingPostEditCustomer: customerArea.isRequesting,
});

const mapDispatchToActions = {
  fetchCustomer: customerActions.fetchCustomer,
  toggleLoading: loadingActions.toggleLoading,
  showLoading: loadingActions.showLoading,
  hideLoading: loadingActions.hideLoading,
  validateOtp: validateActions.validateOtp,
  postOtpCode: otpActions.postOtpCode,
  postEditCustomer: customerAreaActions.postEditCustomer,
  trackVirtualPageviewApp: customerAreaActions.trackVirtualPageviewApp,
  otpTrackClick: otpActions.otpTrackClick,
  updateCustomTo: otpActions.updateCustomTo,
  otpTrackResendClick: otpActions.otpTrackResendClick,
  currentOtpCode: otpActions.currentOtpCode,
};

const customerLoadingID = 'customerLoadingID';

@connect(mapStateToProps, mapDispatchToActions)
export default class CustomerAreaConfirmPage extends Component {
  static propTypes = {
    sendOtp: PropTypes.object,
    failOtp: PropTypes.object,
    fetchCustomer: PropTypes.func.isRequired,
    toggleLoading: PropTypes.func.isRequired,
    showLoading: PropTypes.func.isRequired,
    hideLoading: PropTypes.func.isRequired,
    validateOtp: PropTypes.func.isRequired,
    postOtpCode: PropTypes.func.isRequired,
    postEditCustomer: PropTypes.func.isRequired,
    trackVirtualPageviewApp: PropTypes.func.isRequired,
    otpTrackClick: PropTypes.func.isRequired,
    currentOtpCode: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  state = {
    otpCodeOnly: false,
    otpId: null,
  };

  getCustomer = async () => {
    const { fetchCustomer, customerInfo } = this.props;
    if(isEmpty(customerInfo)){
      await fetchCustomer();
    }
  };

  componentWillMount() {
    const { location } = this.props;
    const { router } = this.context;
    const { pathname } = location;

    miniappValidateSession(router, pathname, location);

    this.getCustomer();
  }

  componentDidMount() {
    const otpIdVerify = !!getFirstOtpId();
    const otpIdChange = !!getSecondOtpId();
    this.setState({ otpCodeOnly: otpIdVerify && otpIdChange });
  }

  componentWillReceiveProps(nextProps) {
    const { customerRequesting, toggleLoading } = nextProps;

    toggleLoading(customerLoadingID, customerRequesting);
  }

  callbackEditSuccess = () => {
    const { location } = this.props;
    const { router } = this.context;
    const query = `opcao=${location?.query?.opcao}&otpSuccess=true`;

    resetOtpId();
    router.push(
      `${channelCustomerArea}?${query}${location.query.deeplink ? '&deeplink=app' : ''}`
    );
  };

  handlePostOtp = async (optionsData, retry = false) => {
    const {
      postOtpCode,
      currentOtpCode,
      otpTrackClick,
      otpTrackResendClick,
    } = this.props;
    const { otpCodeOnly } = this.state;
    const newDeliveryMode = optionsData.delivery_mode;
    const { currentExpiresAt, currentOtpId } = getOtpStorage(newDeliveryMode);
    const duration = moment.duration(moment.unix(currentExpiresAt).diff(moment()));

    if (duration.seconds() <= 0 && duration.minutes() <= 0) {
      const bodyOtpRequest = optionsData;

      if (otpCodeOnly) {
        bodyOtpRequest.session_id = getFirstOtpId();
      }
      const data = await postOtpCode(bodyOtpRequest, 'firstOtp');
      const { id } = data;
      setOtpStorage(newDeliveryMode, data);
      this.setState({ otpId: id });

      if (otpCodeOnly) {
        setSecondOtpId(id);
      } else {
        setFirstOtpId(id);
      }

      const clickTrack = () =>
        retry === true
          ? otpTrackResendClick(newDeliveryMode, 'edit')
          : otpTrackClick(newDeliveryMode, 'edit');

      clickTrack();
    } else {
      this.setState({ otpId: currentOtpId });
      currentOtpCode({ id: getFirstOtpId(), expires_at: currentExpiresAt }, newDeliveryMode);
    }
  };

  handleValidateOtp = async (code) => {
    const {
      validateOtp,
      location,
      sendOtp: { deliveryMode },
    } = this.props;
    const delivery = location.query.opcao;
    const { otpId: id } = this.state;

    const response = await validateOtp({ code, id }, 'validate', deliveryMode);

    if (response === null) {
      const editPath =
        delivery === 'sms'
          ? routeNames.channelCustomerAreaChangeCellphone
          : routeNames.channelCustomerAreaChangeEmail;
      this.context.router.push(
        `${editPath}${location.query.deeplink ? '?deeplink=app' : ''}`
      );
    }
  };

  handleEditCustomer = (code) => {
    const {
      postEditCustomer,
      location,
      sendOtp: { customTo, deliveryMode },
    } = this.props;

    const customerOtpPayload = {
      session_id: getFirstOtpId(),
      otp_id: getSecondOtpId(),
      otp_code: code,
      otp_delivery_mode: deliveryMode,
    };

    const option = location?.query?.opcao;

    if (option === 'email') {
      customerOtpPayload.email = customTo?.email;
    } else {
      const phone = customTo.phone?.replace(/(^(\+)|\D)/g, '');
      customerOtpPayload.phone = {
        type: 'mobile',
        area_code: phone.substring(0, 2),
        number: phone.substring(2, phone.length),
      };
    }

    postEditCustomer(customerOtpPayload, this.callbackEditSuccess);
  };

  onRenderSelectEmailOrSms = () => {
    const { customerInfo, trackVirtualPageviewApp } = this.props;
    if (customerInfo?.customerUuid) {
      trackVirtualPageviewApp(customerInfo, SCREEN_NAME_OTP);
    }
  };

  onRenderOtpCode = (mode) => {
    const { customerInfo, trackVirtualPageviewApp } = this.props;
    if (customerInfo?.customerUuid) {
      trackVirtualPageviewApp(customerInfo, SCREEN_NAME_CODE, mode);
    }
  };

  render() {
    const {
      failOtp: { code: statusCode, failMessage: message },
      sendOtp: {
        expiresAt,
        emailExpiresAt,
        smsExpiresAt,
        isRequesting,
        otpId,
        customTo,
        isError,
        deliveryMode
      },
      customerInfo,
      customerRequesting,
      location,
      updateCustomTo,
      isRequestingValidateOtp,
      isRequestingPostEditCustomer,
    } = this.props;
    const { otpCodeOnly } = this.state;

    const currentExpiresAt = deliveryMode === 'email' ? emailExpiresAt : smsExpiresAt;

    const ENABLE_EMAIL_OTP_EDIT = getChannelConfig('enable_email_otp_edit') ?? true;
    const ENABLE_SMS_OTP_EDIT = getChannelConfig('enable_sms_otp_edit') ?? true;

    if (!customerRequesting) {
      return (
        <section className="CustomerAreaConfirmPage">
          <ValidateAccountOtp
            enableEmail={ENABLE_EMAIL_OTP_EDIT}
            enableSms={ENABLE_SMS_OTP_EDIT}
            isModal={false}
            isOpen
            otpCodeOnly={otpCodeOnly}
            error={{ statusCode, message }}
            hasOtpId={!!otpId || isError}
            expiresAt={currentExpiresAt || expiresAt}
            loading={isRequesting || isRequestingValidateOtp || isRequestingPostEditCustomer}
            onRenderOtpCode={this.onRenderOtpCode}
            onRenderSelectEmailOrSms={this.onRenderSelectEmailOrSms}
            onComplete={otpCodeOnly ? this.handleEditCustomer : this.handleValidateOtp}
            postOtpGenerate={this.handlePostOtp}
            updateCustomTo={updateCustomTo}
            contacts={{
              email: customTo?.email || customerInfo.email,
              sms: customTo?.phone || (customerInfo?.telephone || customerInfo?.mobilePhone),
            }}
            query={location?.query}
          />
        </section>
      );
    }

    return null;
  }
}
