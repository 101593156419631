import React from 'react';
import PropTypes from 'prop-types';

import * as cardFlags from 'constants/card-flags';

import { toBRL } from 'utils/currency';
import { moveCursorToLastChar } from 'utils/moveCursorToLastChar';

import FormGroup from 'components/Form/FormGroup/FormGroup';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import PaymentInfoLabel from 'components/Payment/Forms/PaymentInfoLabel/PaymentInfoLabel';
import CardFlagsBox from 'components/Payment/CardFlagsBox/CardFlagsBox';
import CardFlag from 'components/Payment/CardFlag/CardFlag';
import InputCvvForm from 'components/Form/InputCvvForm';
import PaymentFormButton from 'components/Payment/Forms/PaymentFormButton/PaymentFormButton';
import PaymentExpirationItem from 'components/Payment/Forms/PaymentExpirationItem/PaymentExpirationItem';
import CheckboxGroup from 'components/Form/CheckboxGroup/CheckboxGroup';
import CardIcon from 'icons/payment/icon-credit-card.svg';
import UserIcon from 'icons/icon-user.svg';
import * as customerMessages from 'constants/customer-messages';

export const NewCardForm = ({
  number: numberField,
  fullName,
  expirationMonth,
  expirationYear,
  CVC,
  installments: installmentsField,
  flag,
  saveCard,
  setAsDefault,
  clickedFlag = '',
  disabledFlags = [],
  installmentsList,
  handleFlagClick,
  handleSubmit,
}) => {
  installmentsField.disabled = installmentsList.length === 0;

  return (
    <form className="NewCardForm" onSubmit={handleSubmit} method="post">
      <FormGroup
        labelText="Número do cartão"
        inputSize="long"
        field={numberField}
        hideSuccess
        handleKeyUp={moveCursorToLastChar}
        type="tel"
        autocomplete="off"
        icon={<CardIcon className="FormGroup-icon-card" />}>
        {!numberField.invalid && flag.value && (
          <CardFlag flag={flag.value || cardFlags.unknown} />
        )}
        {!flag.value && (
          <PaymentInfoLabel
            field={numberField}
            label="Preencha para ver o parcelamento"
          />
        )}
      </FormGroup>
      {numberField.error === customerMessages.flagNotSelected &&
        numberField.touched &&
        !numberField.active &&
        !!numberField.value &&
        !flag.value && (
        <CardFlagsBox
          disabledFlags={disabledFlags}
          clickedFlag={clickedFlag}
          handleFlagClick={handleFlagClick}
        />
      )}
      <FormGroup
        labelText="Nome impresso no cartão"
        inputSize="long"
        field={fullName}
        autocomplete="off"
        icon={<UserIcon className="FormGroup-icon-user" />}></FormGroup>
      <PaymentExpirationItem
        labelText="Validade"
        month={expirationMonth}
        year={expirationYear}
      />
      <InputCvvForm CVC={CVC} flag={flag} />
      <Dropdown labelText="Parcelamento" field={installmentsField} inputSize="long">
        <option value="-1">
          {!flag.value ? 'Preencha o campo Número' : 'Selecione'}
        </option>
        {installmentsList.map(
          ({ numberOfInstallments, installmentAmount, interestRate, description }, i) =>
            flag.value === 'magalu_card' && interestRate > 0 ? (
              <option key={i} value={i}>
                {numberOfInstallments}x de {toBRL(installmentAmount)}*
              </option>
            ) : (
              <option key={i} value={i}>
                {description} {toBRL(installmentAmount)}
              </option>
            )
        )}
      </Dropdown>
      <CheckboxGroup
        labelText="Guardar cartão para as próximas compras"
        field={saveCard}
      />
      <CheckboxGroup
        labelText="Definir cartão como padrão para as próximas compras"
        field={setAsDefault}
      />
      <PaymentFormButton paymentType="Novo Cartão" flag={flag.value} />
    </form>
  );
};

NewCardForm.propTypes = {
  number: PropTypes.object.isRequired,
  fullName: PropTypes.object.isRequired,
  expirationMonth: PropTypes.object.isRequired,
  expirationYear: PropTypes.object.isRequired,
  CVC: PropTypes.object.isRequired,
  installments: PropTypes.object.isRequired,
  flag: PropTypes.object.isRequired,
  saveCard: PropTypes.object.isRequired,
  setAsDefault: PropTypes.object.isRequired,
  clickedFlag: PropTypes.string,
  disabledFlags: PropTypes.array,
  installmentsList: PropTypes.array.isRequired,
  handleFlagClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default NewCardForm;
