import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { isEmpty } from 'lodash';

import * as customerMessages from 'constants/customer-messages';
import * as paymentTypes from 'constants/payment-types';

import * as maskers from 'utils/maskers';
import * as validators from 'utils/validators';
import { encrypt } from 'utils/encrypt';

import {
  encryptedSavedCardData,
  savedCardData,
} from 'api/models/payment-models';

import * as orderActions from 'reducers/order';

import SavedCardForm from 'components/Payment/Forms/SavedCardForm/SavedCardForm';

const errorsSavedCardForm = ({
  installments,
  CVC,
  flag
}) => {
  const errors = {};

  if (installments < 0) {
    errors.installments = customerMessages.invalidInstallmentsNumber;
  }

  if (!validators.cvc(CVC, flag)) {
    errors.CVC = customerMessages.invalidCVC;
  }

  return errors;
};

@reduxForm({
  form: 'savedCard',
  fields: [
    'expirationDate',
    'CVC',
    'installments',
    'flag'
  ],
  validate: errorsSavedCardForm
})
@connect(({ order, cardList, installments, basket }) => ({
  ...order,
  ...cardList,
  ...installments,
  ...basket
}), { ...orderActions, initialize })
export default class SavedCardFormContainer extends Component {
  static propTypes = {
    currentType: PropTypes.string,
    installmentsPerType: PropTypes.object.isRequired,
    cardList: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    postPlaceOrder: PropTypes.func.isRequired,
    handlePlaceOrderError: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    redirectToAddress: PropTypes.func.isRequired,
    redirectToBasket: PropTypes.func.isRequired,
    campaignCode: PropTypes.string,
    itemsSku: PropTypes.array,
    enableEncryptedCard: PropTypes.bool,
    tmxSessionID: PropTypes.string
  };

  componentDidMount() {
    const {
      cardList,
      currentType,
      fields,
      initialize: initializeForm
    } = this.props;

    const currentCard = cardList.find(card => card.id === currentType);
    const { expirationMonth, expirationYear, flag } = currentCard;
    const expirationDate = `${maskers.addLeadingZero(expirationMonth)}/${expirationYear}`;
    if (fields.expirationDate.value !== expirationDate) {
      initializeForm('savedCard', {
        expirationDate,
        installments: '-1',
        flag
      }, Object.keys(fields));
    }
  }

  render() {
    const {
      currentType,
      installmentsPerType,
      cardList,
      fields,
      handleSubmit,
      postPlaceOrder,
      handlePlaceOrderError,
      redirectToAddress,
      redirectToBasket,
      enableEncryptedCard,
      items,
      tmxSessionID
    } = this.props;
    const {
      flag,
      id,
      fullName,
      expirationMonth,
      expirationYear,
      number
    } = cardList.find(card => card.id === currentType);

    return (
      <SavedCardForm
        {...fields}
        installmentsList={installmentsPerType[flag] || []}
        handleSubmit={handleSubmit(values => new Promise((resolve, reject) => {
          const { CVC, installments } = values;
          const errors = errorsSavedCardForm(Object.assign({}, values, { flag }));

          if (!isEmpty(errors)) {
            return reject(errors);
          }

          const installmentsQuantity = installmentsPerType[flag][installments].numberOfInstallments;

          const cardData = !!enableEncryptedCard
            ? encryptedSavedCardData({
              id,
              installmentsQuantity,
              encryptedCreditCard: encrypt({
                'card_security_code': CVC,
                'card_holder_name': fullName,
              }),
              flag,
            })
            : savedCardData({
              id,
              CVC,
              installmentsQuantity,
              fullName,
              expirationMonth,
              expirationYear,
              flag,
            });

          return postPlaceOrder(
            {...cardData, items, customer_risk_analysis_id: tmxSessionID},
            paymentTypes.savedCard,
            redirectToAddress,
            redirectToBasket,
            number.charAt(0)
          ).catch((err) => {
            handlePlaceOrderError(err);
          });
        }))}
      />
    );
  }
}
