import React from 'react';

import PropTypes from 'prop-types';

const LUIZA_CARD_METHODS = ['magalucard', 'luizacard', 'luizacard_gold', 'magalu_card'];
const METHOD_MASTERCARD = 'mastercard';

class MastercardSonicAnimation extends React.Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      animationCompleted: false,
    };

    const storageKey = `order-${props.orderId}-digit-0`;
    const orderCardFirstDigit = sessionStorage.getItem(storageKey);
    const paymentMethod = props.paymentMethod.toLowerCase();
    const isMastercard =
      paymentMethod === METHOD_MASTERCARD ||
      (LUIZA_CARD_METHODS.includes(paymentMethod) && orderCardFirstDigit !== '4');

    this.storageKey = storageKey;
    this.show = props.enabled && !!orderCardFirstDigit && isMastercard;
    this.onAnimationCompleted = this.onAnimationCompleted.bind(this);
    this.handleReceivePostMessage = this.handleReceivePostMessage.bind(this);
  }

  removeItemFromStorage() {
    sessionStorage.removeItem(this.storageKey);
  }

  onAnimationCompleted() {
    this.setState({ animationCompleted: true });
    this.removeItemFromStorage();
  }

  handleReceivePostMessage(event) {
    if (event.data === 'animationCompleted') {
      this.onAnimationCompleted();
    }
  }

  componentDidMount() {
    if (this.show) {
      window.addEventListener('message', this.handleReceivePostMessage);
    } else {
      this.removeItemFromStorage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleReceivePostMessage);
  }

  render() {
    if (!this.show || this.state.animationCompleted) {
      return null;
    }

    return (
      <iframe
        sandbox="allow-scripts allow-same-origin"
        src={process.env.REACT_APP_MASTERCARD_SONIC_PATH}
        className="h-full w-full"
      />
    );
  }
}

export default MastercardSonicAnimation;
