import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Success from 'icons/icon-success';
import GreenCheckIcon from 'icons/icon-check-green.svg';

import { showcase as Showcase } from 'constants/recommendation';

import * as routeNames from 'constants/route-names';
import * as paymentTypes from 'constants/payment-types';

import { isLogged } from 'utils/session';
import { redirectToLogin } from 'utils/redirect';

import * as bobbyActions from 'reducers/bobby';
import * as orderActions from 'reducers/order';
import * as loadingActions from 'reducers/loading';
import * as recommendationActions from 'reducers/recommendation';

import ConfirmOrderBoxPrintSlip from 'components/ConfirmOrderBoxPrintSlip';
import ConfirmOrderBoxCopySlip from 'components/ConfirmOrderBoxCopySlip';
import Responsive from 'components/Responsive';
import { ConfirmOrderBox } from 'components/ConfirmOrder/ConfirmOrderBox/ConfirmOrderBox';
import BobbyCarousel from 'components/Bobby/BobbyCarousel';
import Raffle from 'components/ConfirmOrder/Raffle/Raffle';
import OrderBoxPix from 'components/ConfirmOrder/Pix/OrderBoxPix/OrderBoxPix';
import MastercardSonicAnimation from 'components/MastercardSonicAnimation/MastercardSonicAnimation';

import NewsletterOptionContainer from 'containers/NewsletterOptionContainer';
import WhatsAppContainer from 'containers/WhatsAppContainer';

import './ConfirmOrderPage.scss';

const { func, object, bool, string, arrayOf } = PropTypes;

const orderLoadingID = 'orderLoading';

const confirmOrderScreenName = 'pedido-concluido';

const isRecommendationsEnabled = window.MAGALU_CHANNEL?.channel_configs?.enable_recommendations;

const mapStateToProps = ({
  order,
  unauthorized,
  bobby,
  channel,
  recommendation,
}) => ({
  ...order,
  ...unauthorized,
  ...bobby,
  ...recommendation,
  orderRequesting: order.isRequesting,
  confirmOrderTrackUrl: channel.configs.orders_url,
  salesChannel: channel.configs.sales_channel,
  enableNewsletter: channel.configs.enable_newsletter,
  currency: order.currency
});
const mapDispatchToActions = {
  ...bobbyActions,
  ...orderActions,
  ...loadingActions,
  ...recommendationActions,
};

@connect(mapStateToProps, mapDispatchToActions)
class ConfirmOrderPage extends Component {
  static propTypes = {
    order: object.isRequired,
    clear: func.isRequired,
    paymentData: object.isRequired,
    productListView: func.isRequired,
    trendsPurchases: object.isRequired,
    trendsViews: object.isRequired,
    trendsCarts: object.isRequired,
    location: object.isRequired,
    fetchOrder: func.isRequired,
    toggleLoading: func.isRequired,
    orderRequesting: bool.isRequired,
    isMobile: bool,
    confirmOrderTrackUrl: string,
    raffles: arrayOf(string),
    orderPageview: func.isRequired,
    enableNewsletter: bool,
    fetchShowcasesRecommendation: PropTypes.func.isRequired,
  };

  static contextTypes = {
    router: object.isRequired
  };

  componentWillMount() {
    const {
      location,
      fetchOrder,
      fetchShowcasesRecommendation,
    } = this.props;
    const { router } = this.context;
    const { query: { orderId } } = location;

    if (!orderId) {
      return router.replace(routeNames.root);
    }

    if (!isLogged()) {
      return redirectToLogin(router, routeNames.confirmOrder, location);
    }

    fetchOrder(orderId)
      .then(this.props.orderPageview);

    if (isRecommendationsEnabled) {
      const params = {
        pageId: Showcase.conclusao.page_id,
        placementId: Showcase.conclusao.placement_conclusaobottom,
      };

      fetchShowcasesRecommendation({ params }).catch((err) => {
        const json = err.response ? err.response.body : {};
        metricsError('recomendacao', json.error_message);
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      err,
      toggleLoading,
      orderRequesting,
    } = nextProps;
    const { router } = this.context;

    if (err) {
      return redirectToLogin(router, routeNames.confirmOrder, location);
    }

    toggleLoading(orderLoadingID, orderRequesting);
  }

  componentWillUnmount() {
    const { clear, toggleLoading } = this.props;
    clear();

    toggleLoading(orderLoadingID, false);
  }

  handleLinkClick = (product) => {
    this.props.productClick({...product, screenName: 'pedido-concluido'});
    this.props.selectItem({...product, screenName: 'pedido-concluido'});
  }

  onProductImpression = (products, first, last, showcase) => {
    const productsParsed = products.map((product, index) =>
      ({...product, showcase, pageId: Showcase.conclusao.page_id, placementId: Showcase.conclusao.placement_conclusaobottom,
        position: ++index, screenName: confirmOrderScreenName }));
    this.props.productListView(productsParsed, first, last);
    this.props.viewItemList(productsParsed, first, last);
  }
  
  render() {
    const {
      order,
      trendsPurchases,
      trendsViews,
      trendsCarts,
      isMobile,
      confirmOrderTrackUrl,
      raffles = order.raffles,
      salesChannel,
      enableNewsletter = true,
      recommendations,
      currency
    } = this.props;

    const isBankSlip = order.payments && !!order.payments.find(payment =>
      (
        payment.type === paymentTypes.bankSlip
        || payment.type === paymentTypes.marketPlaceBankSlip
      )
    );

    const canShow = !isEmpty(order) && order.totalAmount;
    const renderNewsletter = order.customerNotification && enableNewsletter;

    const carouselName = isMobile
      ? <span>Sugestões especiais para você</span>
      : <span dangerouslySetInnerHTML={{ __html: trendsViews.subject }} />;

    const isPix = order.payments && !!order.payments.find(payment =>
      (payment.type === paymentTypes.pix)
    );

    const {
      conclusao: {
        page_id: pageId,
        placement_conclusaobottom: conclusaoBottom,
      }
    } = Showcase;

    return canShow && (
      <div className="ConfirmOrderPage">
        <div className="ConfirmOrderPage-container">
          <div className="ConfirmOrderHeader clearfix">
            <div className="ConfirmOrderHeader-title--success">
              {!isPix ? (
                <div>
                  {isMobile && <GreenCheckIcon className="ConfirmOrderHeader-title-icon-check" />}
                  Seu pedido foi finalizado com sucesso
                  {' '}
                  {!isMobile && <Success className="ConfirmOrderHeader-title-icon" />}
                </div>
              ) : (
                'Para finalizar a sua compra é só realizar o pagamento com Pix!'
              )}
            </div>

            {isMobile &&
              <ConfirmOrderBox
                {...order}
                confirmOrderTrackUrl={confirmOrderTrackUrl}
                salesChannel={salesChannel}
              >
                {isBankSlip && (
                  <div>
                    <ConfirmOrderBoxCopySlip
                      number={order.bankSlip.number}
                    />
                    <div className="ConfirmOrderBox-bankSlip-info">
                      <p
                        className="ConfirmOrderBox-payment_types-expiration"
                      >
                      (Vencimento: {order.bankSlip.expiration})
                      </p>
                      <p>
                      O boleto também está disponível no e-mail de confirmação de pedido
                      </p>
                    </div>
                  </div>
                )}
              </ConfirmOrderBox>
            }

            {isMobile &&
              <MastercardSonicAnimation
                orderId={order.id}
                enabled={order.enableMastercardSonic ?? false}
                paymentMethod={order.payment?.payment_method_id}
              />
            }

            {isMobile && isPix && <OrderBoxPix pix={order?.payment?.pix} />}

            {isMobile && !order.customerNotification ? <WhatsAppContainer /> : ''}

            {isMobile && renderNewsletter ? <NewsletterOptionContainer /> : ''}

            {!isMobile &&
            <div className="ConfirmOrderHeader-description">
              Obrigado pela compra. Você receberá todos os dados da sua compra no email <span className="ConfirmOrderHeader-email">
                {order.customerEmail}
              </span>.
            </div>
            }
          </div>

          {!isMobile &&
            <div>
              <ConfirmOrderBox
                {...order}
                confirmOrderTrackUrl={confirmOrderTrackUrl}
                salesChannel={salesChannel}
                currency={currency}
              >
                {isBankSlip && (
                  <ConfirmOrderBoxPrintSlip
                    orderId={order.id}
                    barCode={order.barCode}
                  />
                )}
              </ConfirmOrderBox>

              <MastercardSonicAnimation
                orderId={order.id}
                enabled={order.enableMastercardSonic ?? false}
                paymentMethod={order.payment?.payment_method_id}
              />

              {isPix && <OrderBoxPix pix={order?.payment?.pix} />}

              <p className="ConfirmOrderFooter-legalTerms clearfix">
                <p>
                  * O prazo de entrega será contado após 1º dia útil da aprovação do pedido. Este procedimento costuma ocorrer em até 24 horas, mas tem período máximo para acontecer de até 48 horas (pagamento no cartão). Se o pagamento for realizado por boleto bancário, o banco tem o prazo de até três dias úteis para confirmar
                </p>
              </p>

              {!order.customerNotification && <WhatsAppContainer />}

              {renderNewsletter && <NewsletterOptionContainer />}

              <div
                className="Banner-Affinion-container clearfix"
              />

              <div className="Banner-Ebit-container clearfix">
                <div className="Banner-Ebit" />
              </div>

              <div className="Banner-Ebit-Affinion-container clearfix">
                <div className="Banner-Ebit--half" />
                <div className="Banner-affinion--half" />
              </div>
            </div>
          }

          {!!raffles && raffles.map((raffle, index) => (
            <Raffle
              key={`raffle-${index}`}
              content={raffle}
            />
          )
          )
          }

          {!recommendations.isEmpty() &&
            recommendations
              .getShowcases(pageId, conclusaoBottom)
              .map(showcase => {
                return !isEmpty(showcase.products) ? (
                  <BobbyCarousel
                    key={showcase.id}
                    titleComponent={<span dangerouslySetInnerHTML={{ __html: showcase.title }} />}
                    products={showcase.products}
                    carouselName={showcase.mobileSubject}
                    handleLinkClick={this.handleLinkClick}
                    onProductImpression={this.onProductImpression}
                  />
                ) : null;
              })
          }

          {isMobile && !isEmpty(trendsViews?.products) && <BobbyCarousel
            titleComponent={carouselName}
            products={trendsViews?.products}
            carouselName={trendsViews?.mobileSubject}
            handleLinkClick={this.handleLinkClick}
            onProductImpression={this.onProductImpression}
          />}

          {!isMobile && !isEmpty(trendsViews?.products) && <BobbyCarousel
            titleComponent={carouselName}
            products={trendsViews?.products}
            carouselName={trendsViews?.mobileSubject}
            handleLinkClick={this.handleLinkClick}
            onProductImpression={this.onProductImpression}
          />}

          <div>
            <div className="Banner-epoca" />
            {!isMobile && !isEmpty(trendsPurchases?.products) && <BobbyCarousel
              titleComponent={<span dangerouslySetInnerHTML={{ __html: trendsPurchases?.subject }} />}
              products={trendsPurchases?.products}
              carouselName={trendsPurchases?.mobileSubject}
              handleLinkClick={this.handleLinkClick}
              onProductImpression={this.onProductImpression}
            />}

            {!isMobile && !isEmpty(trendsCarts.products) && <BobbyCarousel
              titleComponent={<span dangerouslySetInnerHTML={{ __html: trendsCarts.subject }} />}
              products={trendsCarts.products}
              carouselName={trendsCarts.mobileSubject}
              handleLinkClick={this.handleLinkClick}
              onProductImpression={this.onProductImpression}
            />}
          </div>

          <div className="Banner-adx" />

        </div>
      </div>
    ) || null;
  }
}


export default Responsive(ConfirmOrderPage);
