
export const OTP_EMAIL_EXPIRES_AT = 'otpEmailExpiresAt';
export const OTP_SMS_EXPIRES_AT = 'otpSmsExpiresAt';
export const OTP_EMAIL_ID = 'otpEmailId';
export const OTP_SMS_ID = 'otpSmsId';

export const OTP_KEYS = {
  email: {
    expiresAt: OTP_EMAIL_EXPIRES_AT,
    id: OTP_EMAIL_ID,
  },
  sms: {
    expiresAt: OTP_SMS_EXPIRES_AT,
    id: OTP_SMS_ID,
  },
};
