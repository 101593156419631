import React from 'react';
import PropTypes from 'prop-types';

import {
  checkSimilar
} from 'constants/customer-messages';

import * as maskers from 'utils/maskers';

const BasketUnavailableWarning = ({
  zipcode,
  subcategoryUrl,
  id,
  statusMessage
}) => {
  const dataGA = JSON.stringify({
    'category': 'Produto Indisponível - MultiCD Após Consulta de CEP',
    'action': id,
    'label': 'Confira Similares',
  });

  return (
    <div className="BasketItem-unavailableWarning">
      {statusMessage} {maskers.zipcode(zipcode)}. <a
        className="BasketItem-unavailableWarning-link"
        href={`${subcategoryUrl}`}
        data-ga={dataGA}
      >
        {checkSimilar}
      </a>
    </div>
  );
};

BasketUnavailableWarning.propTypes = {
  zipcode: PropTypes.string.isRequired,
  subcategoryUrl: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  statusMessage: PropTypes.string
};

export default BasketUnavailableWarning;
