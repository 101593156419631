import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import * as routeNames from 'constants/route-names';
import * as paymentTypes from 'constants/payment-types';
import { NATIONAL_STORAGE_KEY } from 'constants/cookies';

import { isLogged, getChannelConfig, tmxGenerateSessionID } from 'utils/session';
import { redirectToLogin } from 'utils/redirect';
import { storesHaveSameDayPickup, hasSameDayDelivery } from 'utils/packages';

import * as unauthorizedActions from 'reducers/unauthorized';
import * as basketActions from 'reducers/basket';
import * as cardListActions from 'reducers/card-list';
import * as allowedBinsActions from 'reducers/allowed-bins';
import * as loadingActions from 'reducers/loading';
import * as installmentActions from 'reducers/installments';
import * as orderActions from 'reducers/order';

import PaymentBoxContainer from 'containers/PaymentBoxContainer';
import PromocodeContainer from 'containers/PromocodeContainer';

import OrderReview from 'components/OrderReview/OrderReview';
import PaymentContract from 'components/PaymentContract';
import Responsive from 'components/Responsive';
import { GenericFooter } from 'components/Footer/GenericFooter/GenericFooter';
import redirectToWallet from 'utils/redirectToWallet';
import isWalletEnable from 'utils/isWalletEnable';

import './PaymentPage.scss';

const { array, object, func, bool, string, number } = PropTypes;

const orderLoadingID = 'orderLoading';
const basketLoadingID = 'basketLoading';
const cardListLoadingID = 'cardListLoading';
const installmentsLoadingID = 'installmentsLoading';
const useCreditLoadingID = 'useCreditLoading';
const allowedBinsLoadingID = 'allowedBinsLoading';

const mapStateToProps = ({
  order,
  unauthorized,
  basket,
  cardList,
  allowedBins,
  installments,
  promocode,
  channel,
  form,
}) => ({
  ...order,
  shippingPackages: basket.shippingPackages,
  totals: basket.shippingPackages.totals,
  ...unauthorized,
  orderRequesting: order.isRequesting,
  basketRequesting: basket.isRequesting,
  cardListRequesting: cardList.isRequesting,
  installmentsRequesting: installments.installmentsRequesting,
  useCreditRequesting: basket.useCreditRequesting,
  useCredit: basket.useCredit,
  promocode: promocode.promocode,
  promocodeInput: promocode.promocodeInput,
  isPromocodeEnabled: channel.configs.enable_promocode,
  savedCardFlag: form.savedCard.flag,
  newCardFlag: form.newCard.flag,
  discounts: basket.discounts,
  campaignCode: basket.campaignCode,
  productCount: basket.basket.productCount,
  allowedBins,
  allowedBinsRequesting: allowedBins.isRequesting,
  hasBinRestriction: channel.configs.bin_restriction,
  firstPartySeller: channel.first_party_seller,
});

const mapDispatchToActions = {
  ...cardListActions,
  ...allowedBinsActions,
  ...basketActions,
  ...unauthorizedActions,
  ...loadingActions,
  ...orderActions,
  resetInstallments: installmentActions.reset,
  fetchInstallments: installmentActions.fetchInstallments,
  resetInstallmentsSelect: installmentActions.resetInstallmentsSelect,
  updateCredit: basketActions.updateCredit,
};

@connect(mapStateToProps, mapDispatchToActions)
class PaymentPage extends PureComponent {
  static propTypes = {
    err: object,
    order: object,
    shippingPackages: object.isRequired,
    totals: object,
    discounts: object,
    reset: func.isRequired,
    fetchCardList: func.isRequired,
    fetchAllowedBins: func.isRequired,
    fetchPackages: func.isRequired,
    toggleLoading: func.isRequired,
    basketRequesting: bool.isRequired,
    orderRequesting: bool.isRequired,
    cardListRequesting: bool.isRequired,
    installmentsRequesting: array.isRequired,
    resetInstallments: func.isRequired,
    fetchInstallments: func.isRequired,
    resetInstallmentsSelect: func.isRequired,
    updateCredit: func.isRequired,
    clearCardList: func.isRequired,
    resetCurrentType: func.isRequired,
    promocode: string,
    promocodeInput: string,
    isPromocodeEnabled: bool.isRequired,
    savedCardFlag: object,
    newCardFlag: object,
    currentType: string,
    useCredit: bool,
    isMobile: bool,
    campaignCode: string,
    productCount: number,
    fetchBasketForGTM: func.isRequired,
    allowedBins: object,
    allowedBinsRequesting: bool.isRequired,
    hasBinRestriction: bool,
    firstPartySeller: string.isRequired,
    paymentCheckoutProgress: func.isRequired,
    basketTax: func.isRequired,
    paymentShippingCalcImpression: func.isRequired,
    paymentPageview: func.isRequired,
  };

  static contextTypes = {
    router: object.isRequired,
  };

  state = {
    isCreditActiveOnPromocodeChange: false,
    issuedCardLuiza: false,
    getInstallments: null,
    tmxSessionID: null,
  };

  componentWillMount() {
    const {
      fetchCardList,
      fetchAllowedBins,
      fetchPackages,
      fetchBasketForGTM,
      hasBinRestriction,
      paymentCheckoutProgress,
      basketTax,
      paymentShippingCalcImpression,
      paymentPageview,
    } = this.props;
    const { router } = this.context;

    if (!isLogged()) {
      return redirectToLogin(router);
    }

    if(isWalletEnable()){
      redirectToWallet(routeNames.payment);
      return;
    }

    fetchBasketForGTM();

    fetchPackages()
      .then((res) => {
        if (!isEmpty(res)) {
          paymentPageview();
          paymentCheckoutProgress();
          basketTax('pagamento');
          paymentShippingCalcImpression();
          fetchCardList({ hasMarketplaceItems: res.hasMarketplaceItems });
          if (hasBinRestriction) {
            fetchAllowedBins();
          }
        }
      })
      .catch((err) => {
        if (err.status === 404) {
          return router.push(routeNames.root);
        } else if (err.status === 400) {
          return router.push(routeNames.address);
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    const {
      err,
      reset,
      order = {},
      toggleLoading,
      basketRequesting,
      orderRequesting,
      cardListRequesting,
      installmentsRequesting,
      useCreditRequesting,
      hasBinRestriction,
      allowedBinsRequesting,
    } = nextProps;
    const { router } = this.context;

    if (err) {
      redirectToLogin(router);

      return reset();
    }

    if (!isEmpty(order)) {
      if (sessionStorage.getItem(NATIONAL_STORAGE_KEY)) {
        return (window.location = `/n#/comprovante/${order.id}`);
      }

      return router.push({
        pathname: `/${routeNames.confirmOrder}`,
        query: { orderId: order.id },
      });
    }

    toggleLoading(orderLoadingID, basketRequesting);
    toggleLoading(basketLoadingID, orderRequesting);
    toggleLoading(cardListLoadingID, cardListRequesting);
    toggleLoading(installmentsLoadingID, !isEmpty(installmentsRequesting));
    toggleLoading(useCreditLoadingID, useCreditRequesting);
    if (hasBinRestriction) {
      toggleLoading(allowedBinsLoadingID, allowedBinsRequesting);
    }
  }

  componentWillUnmount() {
    const {
      resetInstallments,
      toggleLoading,
      clearCardList,
      resetCurrentType,
      hasBinRestriction,
    } = this.props;

    resetInstallments();
    clearCardList();
    resetCurrentType();

    toggleLoading(orderLoadingID, false);
    toggleLoading(basketLoadingID, false);
    toggleLoading(cardListLoadingID, false);
    toggleLoading(installmentsLoadingID, false);
    if (hasBinRestriction) {
      toggleLoading(allowedBinsLoadingID, false);
    }
  }

  onChangePromocode = () => {
    if (this.state.isCreditActiveOnPromocodeChange) {
      this.props.updateCredit(true).then(() => {
        this.updateInstallments();
      });
    } else {
      this.updateInstallments();
    }
  };

  updateInstallments = () => {
    if (this.props.savedCardFlag && this.props.savedCardFlag.value) {
      this.props
        .fetchInstallments(this.props.savedCardFlag.value)
        .then(() => this.props.resetInstallmentsSelect('savedCard'));
    } else if (this.props.currentType === paymentTypes.newCard) {
      if (this.props.newCardFlag.value) {
        this.props
          .fetchInstallments(this.props.newCardFlag.value)
          .then(() => this.props.resetInstallmentsSelect('newCard'));
      }
    } else if (this.props.currentType === paymentTypes.bankSlip) {
      this.props.fetchInstallments(paymentTypes.bankSlip);
    } else if (this.props.currentType === paymentTypes.pix) {
      this.props.fetchInstallments(paymentTypes.pix);
    }
  };

  initChangePromocode = () => {
    this.setState({
      isCreditActiveOnPromocodeChange: this.props.useCredit,
    });
  };

  redirectToBasket = () => {
    const { router } = this.context;
    return router.push({ pathname: `/${routeNames.root}` });
  };

  redirectToAddress = () => {
    const { router } = this.context;
    return router.push({ pathname: `/${routeNames.address}` });
  };

  componentDidMount = () => {
    if (getChannelConfig('enable_threat_metrix_place_order')) {
      const orgId = getChannelConfig('tmx_org_id') || '5cfbehmb';
      const sessionID = tmxGenerateSessionID();

      window.threatmetrix?.profile('d.mlcdn.com.br', orgId, sessionID);

      this.setState({
        tmxSessionID: sessionID,
      });
    }
  };

  render() {
    const {
      shippingPackages: {
        packagesCount,
        shippingTypes: { deliveries, stores } = {},
        accountType,
        giftCardInfo,
      },
      promocode,
      promocodeInput,
      totals,
      isPromocodeEnabled,
      isMobile,
      discounts,
      firstPartySeller,
      productCount,
    } = this.props;

    const tmxSessionID = this.state.tmxSessionID;

    const promocodeComponent = isPromocodeEnabled ? (
      <PromocodeContainer
        promocode={promocode}
        promocodeInput={promocodeInput}
        initChangePromocode={this.initChangePromocode}
        onChangePromocode={this.onChangePromocode}
      />
    ) : null;

    return (
      <div className="PaymentPage">
        {accountType === 'pj' && (
          <div className="PaymentPage-PJWarning">
            Aviso aos clientes pessoa jurídica: Esta compra é realizada por uma pessoa
            jurídica, em operação interestadual, e por tal razão poderá ser cobrado um
            diferencial de alíquota de ICMS pelo Estado de destino da mercadoria. Esta
            obrigação legal está prevista na legislação tributária vigente, e é de
            responsabilidade da pessoa jurídica compradora, razão pela qual o Magazine
            Luiza não se responsabiliza pelo pagamento e/ou liberação do produto, caso
            haja cobrança e/ou retenção do mesmo em barreiras fiscais.
          </div>
        )}

        {!isEmpty(totals) && (
          <OrderReview
            totals={totals}
            discounts={discounts}
            deliveries={deliveries}
            stores={stores}
            packagesCount={packagesCount}
            hasGiftCard={!isEmpty(giftCardInfo)}
            promocodeComponent={isMobile ? promocodeComponent : null}
            firstPartySeller={firstPartySeller}
            productCount={productCount}
            isMobile={isMobile}
          />
        )}

        {!isMobile && promocodeComponent}

        <div className="PaymentPage-title">Escolha a forma de pagamento</div>
        <PaymentBoxContainer
          redirectToAddress={this.redirectToAddress}
          redirectToBasket={this.redirectToBasket}
          allowedBins={this.props.allowedBins.allowedBins}
          tmxSessionID={tmxSessionID}
          deliveries={deliveries}
        />

        <GenericFooter pageFull>
          <PaymentContract
            hasSameDayPickup={storesHaveSameDayPickup(stores)}
            hasSameDayDelivery={hasSameDayDelivery(deliveries?.packages)}
          />
        </GenericFooter>
      </div>
    );
  }
}

export default Responsive(PaymentPage);
