export function getLocalStorageItem(key) {
  const value = localStorage.getItem(key);

  try {
    return JSON.parse(value);
  } catch (error) {
    throw new Error(error.message);
  }
}

export function setLocalStorageItem(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}
